body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
	font-family: regular;
	src: url(/static/media/AvenirNextLTPro-Regular.b5ed9330.otf);
}

@font-face {
	font-family: semibold;
	src: url(/static/media/AvenirNextLTPro-Demi.553decd4.otf);
}

@font-face {
	font-family: sf;
	src: url(/static/media/SF-Pro-Display-Bold.01a151b8.otf);
}

@font-face {
	font-family: medium;
	src: url(/static/media/AvenirLTStd-Medium.11f78470.otf);
}

@font-face {
	font-family: bold;
	src: url(/static/media/AvenirNextLTPro-Bold.91d8ff8a.otf);
}

@font-face {
	font-family: serif-regular;
	src: url(/static/media/Vollkorn-Regular.9749b27b.ttf);
}

@font-face {
	font-family: serif-semibold;
	src: url(/static/media/Vollkorn-SemiBold.b00da683.ttf);
}

@font-face {
	font-family: farewell;
	src: url(/static/media/Inter-Medium.1aa99aa2.ttf);
}

@font-face {
	font-family: farewell-bold;
	src: url(/static/media/Inter-Bold.cef517a1.ttf);
}

html {
  scroll-behavior: smooth;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
	background-color: white; /* #f2f2f2 */
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

a {
	text-decoration: none;
	color: inherit;
}

.main-container {
	min-height: 100vh;
	overflow: hidden;
	display: block;
	position: relative;
}

.top-container {
	width: 62%;
	display: block;
	margin: 0px auto;
	padding-top: 30px;
}

.nav-container {
	overflow: auto;
	/* background-color: #f3f3f3; */
}

.sticky-nav-container {
	overflow: auto;
	position: fixed;
	background-color: white;
	width: 100%;
	top: 0;
	padding-bottom: 10px;
	padding-top: 5px;
	border-bottom: 1px solid #E7E7E7;
	z-index: 9999;
}

.sticky-nav-inside-container {
	width: 62%;
	margin: 0px auto;
}

@media only screen and (max-width: 1500px) {
	.sticky-nav-inside-container {
		width: 75%;
	}
}

@media only screen and (max-width: 1230px) {
	.sticky-nav-inside-container {
		width: 85%;
	}
}

.header-container {
	height: 650px;
	width: 100%;
}

.header-container-left-text {
	padding-top: 135px;
	padding-left: 20px;
	width: 40%;
	float: left;
}

.header-container-right {
	width: 56%;
	float: right;
}

.header-title {
	font-family: sf;
	/* font-weight: 700; */
	font-size: 36px;
}

.header-subtitle {
	margin-top: 20px;
	color: #737373;
	font-size: 18px;
	line-height: 1.5em;
}

.header-button {
	background-color: #0084FF;
	display: inline-block;
	margin-top: 31px;
	font-weight: 500;
	color: white;
	font-size: 17px;
	padding: 11px 19px 13px 21px;
	border-radius: 8px;
	transition: ease all .15s;
	cursor: pointer;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	font-weight: 600;
	margin-right: 10px;
	float: left;
}

.manifesto-container .header-button {
	font-weight: 600;
	background-color: #0084FF;
}

.header-button:hover {
	-webkit-transform: scale(0.98);
	        transform: scale(0.98);
}

.header-button:active {
	-webkit-transform: scale(0.95);
	        transform: scale(0.95);
}

.app-store-button {
	width: 150px;
	margin-top: 25px;
	cursor: pointer;
	transition: ease all .15s;
	margin-left: -5px;
}

.app-store-button:hover {
	-webkit-transform: scale(0.98);
	        transform: scale(0.98);
}

.app-store-button:active {
	-webkit-transform: scale(0.95);
	        transform: scale(0.95);
}

.play-store-button {
	width: 150px;
	margin-top: 50px;
	margin-left: 12px;
	opacity: .3;
}

.header-image {
	width: 580px;
	margin-top: 35px;
}

.mobile-header-image {
	width: 45%;
	display: block;
	margin: 0px auto;
	height: 325px;
	object-fit: cover;
	object-position: top;
}

.logo-container {
	display: flex;
	float: left;
	/* cursor: pointer; */
	padding: 8px 14px 8px 10px;
	border-radius: 8px;
	transition: ease all .15s;
}

/* .logo-container:hover {
	background-color: #f3f3f3;
}

.logo-container:active {
	transform: scale(0.95);
} */

.nav-right-container {
	float: right;
	margin-top: 16px;
}

.nav-button {
	font-weight: 600;
	font-size: 17px;
	color: #737373;
	cursor: pointer;
	float: right;
	margin-left: 30px;
	transition: ease all .15s;
	padding: 5px 10px 5px 10px;
	border-radius: 8px;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

.nav-button:hover {
	background-color: #f3f3f3;
}

.nav-button:active {
	-webkit-transform: scale(0.95);
	        transform: scale(0.95);
}

/* .nav-button-active {
	background-color: #f3f3f3;
	transform: scale(0.95);
} */

.social-button {
	margin-left: 10px;
	margin-right: -10px;
}

.email-button {
	margin-left: 11px;
	color: #AAAAAA;
	display: none;
	font-size: 18px;
}

.fa-facebook-square {
	color: #3C5A99;
}

.fa-twitter {
	color: #1DA1F2;
}

.logo-image {
  width: 36px;
	height: 36px;
  float: left;
  margin-right: 3px;
	margin-top: 4px;
	transition: ease all .15s;
}

.logo-text {
	font-family: sf;
	/* font-weight: 700; */
  font-size: 34px;
  padding-top: 1px;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	transition: ease all .15s;
}

.beta-text {
	font-size: 16px;
	color: #AAAAAA;
	margin-left: 5px;
	font-weight: 500;
}

.shrink  .logo-container {
	padding: 2px 14px 8px 10px;
	margin-top: 3px;
}

.shrink .logo-image {
	width: 30px;
	height: 30px;
	margin-top: 9px;
}

.shrink .logo-text {
	font-size: 28px;
	margin-top: 6px;
}

.shrink .beta-text {
	font-size: 15px;
}

.shrink .nav-button {
	font-size: 16px;
}

.video-label {
	color: #888888;
	margin-top: 14px;
	font-size: 15px;
	cursor: pointer;
	transition: ease all .15s;
}

.video-label i {
	margin-right: 5px;
}

.video-label:active {
	-webkit-transform: scale(0.98);
	        transform: scale(0.98);
}

.graphic-section {
	background-color: #F3F3F3;
	width: 100%;
	margin-top: 0px;
	padding-bottom: 25px;
}

.process-container {
	background-color: white;
	width: 100%;
	margin-top: 0px;
	padding-bottom: 40px;
}

.values-container {
	background-color: #f3f3f3;
	width: 100%;
	padding-bottom: 75px;
}

.contribute-container {
	background-color: #f3f3f3;
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	padding-bottom: 115px;
}

.reviews-container {
	background-color: #f3f3f3;
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	padding-bottom: 120px;
	padding-top: 40px;
}

.contribute-subtitle {
	width: 35%;
	margin-top: 24px;
	text-align: center;
	line-height: 1.4em;
	color: #333333;
	font-size: 17px;
}

.contribute-subtitle a {
	border-bottom: 1px solid #333333;
}

.slack-button {
	background-color: #6F84D2;
	margin-top: 25px;
	border-radius: 8px;
	color: white;
	text-align: center;
	font-weight: 600;
	cursor: pointer;
	transition: ease all .1s;
	padding: 2px 15px 0px 19px;
}

.slack-button:hover {
	-webkit-transform: scale(0.98);
	        transform: scale(0.98);
}

.slack-button:active {
	-webkit-transform: scale(0.95);
	        transform: scale(0.95);
}

.slack-button i {
	font-size: 20px;
	margin-right: 8px;
	vertical-align: top;
}

.discord-logo {
	height: 40px;
	margin-top: 5px;
}

.slack-button-label {
	float: left;
	margin-top: 13px;
	margin-right: 7px;
	font-weight: 700;
}

.manifesto-section {
	background-color: white;
	width: 100%;
	padding-bottom: 75px;
	margin-top: 0px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.manifesto-container {
	width: 800px;
	font-size: 20px;
	margin-top: 50px;
	background-color: #f3f3f3;
	padding: 35px 40px 35px 40px;
	border-radius: 12px;
}

.section-title {
	text-align: center;
	/* font-weight: 700; */
	font-family: sf;
	font-size: 30px;
	padding-top: 50px;
}

.top-section-title {
	padding-top: 60px;
}

.section-subtitle {
	text-align: center;
	margin-top: 10px;
	color: #737373;
	font-size: 18px;
}

.graphic-image {
	padding-top: 45px;
	display: block;
	margin: 0px auto;
	width: 69%;
	max-width: 1250px;
}

.screenshot-tiles-container {
	margin-top: 30px;
	display: flex;
	justify-content: center;
	flex-flow: row wrap;
	align-content: space-between;
}

.screenshot-tile {
	border-radius: 12px;
	width: 260px;
	margin-left: 17px;
	margin-right: 17px;
	padding-top: 30px;
	padding-bottom: 30px;
}

.screenshot-tile-image {
	width: 85%;
	height: 240px;
	object-fit: cover;
	object-position: top;
	display: block;
	margin: 0px auto;
  -webkit-mask-image: linear-gradient(to bottom, rgba(0,0,0,1) 40%, rgba(0,0,0,0) 95%);
          mask-image: linear-gradient(to bottom, rgba(0,0,0,1) 40%, rgba(0,0,0,0) 95%);
}

.screenshot-tile .tile-title {
	font-size: 21px !important;
}

.screenshot-tile .tile-subtitle {
	width: 95% !important;
}

.info-tiles-container {
	margin-top: 50px;
	display: flex;
	justify-content: center;
	flex-flow: row wrap;
}

.info-tile {
	background-color: white;
	border-radius: 12px;
	width: 235px;
	margin-left: 17px;
	margin-right: 17px;
	padding-top: 25px;
	padding-bottom: 23px;
}

.tile-icon-container {
	width: 100%;
	border-radius: 12px 12px 0px 0px;
	text-align: center;
}

.tile-icon-container i {
	font-size: 36px;
}

.mobile-tile-icon-container {
	display: none;
}

.tile-1 {
	/* border: 2px solid #E40021; */
}

.tile-1 .far {
	color: #E40021;
}

.tile-2 {
	/* border: 2px solid #244FB7; */
}

.tile-2 .far {
	color: #244FB7;
}

.tile-3 {
	/* border: 2px solid #FFB500; */
}

.tile-3 .far {
	color: #FFB500;
}

.tile-4 {
	/* border: 2px solid #00D400; */
}

.tile-4 .far {
	color: #58C13A;
}

.tile-title {
	text-align: center;
	/* font-weight: 600; */
	font-family: sf;
	width: 60%;
	display: block;
	margin: 0px auto;
	margin-top: 15px;
	font-size: 20px;
}

.tile-subtitle {
	font-size: 16px;
	text-align: center;
	width: 88%;
	display: block;
	margin: 0px auto;
	margin-top: 10px;
	color: #666666;
	line-height: 1.4em;
}

.footer-container {
	width: 100%;
	padding-top: 20px;
	padding-bottom: 20px;
	background-color: white;
	position: absolute;
 	bottom: 0;
}

.footer-text {
	color: #888888;
	text-align: center;
	font-weight: 500;
	font-size: 14px;
}

.footer-text a {
	font-weight: 600;
	color: #444444;
}

.footer-text a:hover {
	opacity: .7;
}

.mobile-footer-text {
	display: none;
}

.contact-tooltip {
	background-color: white !important;
	box-shadow: 0px 2px 15px rgba(0,0,0,0.10) !important;
	/* border: 2px solid #f3f3f3 !important; */
	border-radius: 12px !important;
	padding: 14px 24px 16px 24px !important;
	opacity: 1 !important;
	pointer-events: auto !important;
	margin-top: 14px !important;
}

.contact-tooltip:after {
	border-left: 0px solid transparent !important;
	border-right: 0px solid transparent !important;
	border-bottom-width: 0px !important;
}

.tooltip-email-message {
	font-size: 15px;
	text-align: center;
	font-weight: 600;
	margin-top: 5px;
}

.tooltip-email-button {
	font-weight: 600;
	font-size: 15px;
	margin-top: 12px;
	text-align: center;
	cursor: pointer;
	background-color: #f3f3f3;
	padding: 6px 10px 6px 11px;
	color: #777777;
	border-radius: 5px;
	transition: ease transform .15s;
}

.tooltip-email-button:hover {
	-webkit-transform: scale(0.95);
	        transform: scale(0.95);
}

.tooltip-social-buttons-container {
	display: block;
	margin: 0px auto;
	width: 66%;
	margin-top: 12px;
}

.tooltip-twitter-button {
	float: left;
	font-size: 17px;
	cursor: pointer;
	padding: 5px 7px 5px 8px;
	border-radius: 5px;
}

.tooltip-facebook-button {
	float: left;
	font-size: 17px;
	cursor: pointer;
	padding: 5px 7px 5px 8px;
	border-radius: 5px;
}

.tooltip-reddit-button {
	color: #FF4500;
	float: right;
	font-size: 17px;
	cursor: pointer;
	padding: 5px 7px 5px 8px;
	border-radius: 5px;
}

.tooltip-twitter-button:hover, .tooltip-facebook-button:hover {
	background-color: #f3f3f3;
}

.tooltip-twitter-button:active, .tooltip-facebook-button:active {
	-webkit-transform: scale(0.95);
	        transform: scale(0.95);
}

.ticker-container {
	width: 50000px;
	padding-top: 18px;
	padding-bottom: 25px;
	margin-top: -10px;
	white-space: nowrap;
  overflow: hidden;
  -webkit-animation: marquee 450s linear infinite;
          animation: marquee 450s linear infinite;
}

.ticker-container:hover {
	-webkit-animation-play-state: paused;
	        animation-play-state: paused;
}

.ticker-label {
	width: 60%;
	display: block;
	margin: 0px auto;
	color: #969696;
	font-weight: 600;
}

.ticker-label .fas {
	margin-right: 5px;
}

.feeds-count {
	background-color: red;
	color: white;
	padding: 6px 6px 3px 6px;
	font-family: bold;
	border-radius: 12px;
	font-size: 15px;
}

.slug-container {
	display: inline-block;
	float: left;
	marign-right: 20px;
	color: white;
	font-family: semibold;
	padding: 8px 12px 6px 12px;
	font-size: 17px;
	border-radius: 30px;
	margin-right: 15px;
	margin-top: 12px;
	cursor: default;
	transition: ease transform .15s;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

@-webkit-keyframes marquee {
    0%   { -webkit-transform: translate(0, 0); transform: translate(0, 0); }
    100% { -webkit-transform: translate(-9000px, 0); transform: translate(-9000px, 0); }
}

@keyframes marquee {
    0%   { -webkit-transform: translate(0, 0); transform: translate(0, 0); }
    100% { -webkit-transform: translate(-9000px, 0); transform: translate(-9000px, 0); }
}

/* .slug-container:hover {
	transform: scale(0.97);
}

.slug-container:active {
	transform: scale(0.94);
} */

.slug-container .fas {
	margin-right: 5px;
	font-size: 15px;
}

.tiles-container {
	display: flex;
	flex-wrap: wrap;
	margin-top: 40px;
	padding-bottom: 130px;
}

.feed-tile-container {
	overflow: auto;
}

.about-container {
	padding-bottom: 130px;
	width: 1000px;
	display: block;
	margin: 0px auto;
	margin-top: 60px;
	display: flex;
  justify-content: center;
  align-items: center;
	flex-direction: column;
}

.feed-tile {
	width: 200px;
	margin-bottom: 30px;
	margin-left: 16px;
	margin-right: 16px;
	padding-bottom: 10px;
	border-radius: 12px;
	transition: ease transform .15s;
	cursor: pointer;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

.feed-tile:hover {
	-webkit-transform: scale(0.98);
	        transform: scale(0.98);
}

.feed-tile:active {
	-webkit-transform: scale(0.95);
	        transform: scale(0.95);
}

.feed-tile-image {
	width: 100%;
	height: 55px;
	border-radius: 12px 12px 0px 0px;
	object-fit: cover;
}

.feed-tile-slug {
	display: inline-block;
	color: white;
	font-family: semibold;
	padding: 6px 10px 3px 11px;
	border-radius: 15px;
	margin-top: 9px;
	margin-left: 10px;
	font-size: 17px;
	cursor: pointer;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	background-color: rgb(255, 255, 255, 0.2);
}

.feed-tile-slug .fas {
	margin-right: 4px;
	font-size: 14px;
}

.feed-description {
	font-size: 13px;
	color: white;
	opacity: .8;
	font-weight: 500;
	margin-left: 12px;
	margin-right: 10px;
	margin-top: 8px;
	height: 50px;
	overflow: hidden;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

.feed-metadata {
	font-size: 13px;
	color: white;
	margin-top: 8px;
	font-weight: 500;
	opacity: .8;
	margin-left: 13px;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

.feed-metadata .fas {
	margin-right: 4px;
	font-size: 10px;
}

.feed-metadata .fa-sync-alt {
	margin-left: 7px;
}

.loading-container {
	display: block;
	margin: 0px auto;
	width: 150px;
	margin-top: 150px;
}

.feed-container {
	background-color: #f3f3f3;
}

.feed-header {
	width: 100%;
	display: block;
	margin: 0px auto;
	padding-bottom: 20px;
	overflow: auto;
	position: fixed;
}

.feed-header-image {
	height: 100px;
	width: 100%;
	object-fit: cover;
}

.feed-header-top-container {
	display: block;
}

.feed-header-bottom-container {
	display: block;
}

.feed-header .slug-container {
	background-color: rgb(255, 255, 255, .20) !important;
	font-size: 19px !important;
	padding: 6px 13px 4px 13px !important;
	margin-left: 16px !important;
	margin-top: 14px !important;
	cursor: default !important;
}

.feed-header .slug-container .fas {
	font-size: 17px !important;
}

.feed-header .slug-container:hover {
	-webkit-transform: scale(1) !important;
	        transform: scale(1) !important;
}

.feed-header .slug-container:active {
	-webkit-transform: scale(1) !important;
	        transform: scale(1) !important;
}

.feed-header .header-description-container {
	color: white;
	float: left;
	font-weight: 500;
	opacity: 0.8;
	font-size: 15px;
	margin-left: 18px;
	margin-top: 12px;
}

.feed-updates-container {
	padding-top: 223px;
}

.feed-update-container {
	background-color: white;
	width: calc(90% - 20px);
	display: block;
	margin: 0px auto;
	margin-top: 18px;
	border-radius: 5px;
	padding: 7px 10px 7px 10px;
}

.mission-text {
	font-size: 28px;
	font-weight: 600;
	text-align: center;
	padding: 8px 14px 8px 14px;
	border-radius: 12px;
	background: #6723d5;
	color: white;
	background: linear-gradient(to right, #6723d5 1%,#6723d5 1%,#244fb7 18%,#e40021 46%,#f67700 66%,#f67700 76%,#58C13A 94%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6723d5', endColorstr='#58C13A',GradientType=1 );
}

.about-header {
	font-size: 20px;
	font-weight: 600;
	margin-top: 60px;
	margin-bottom: 20px;
	background-color: #f3f3f3;
	padding: 8px 14px 8px 14px;
	border-radius: 12px;
}

.about-text-section {
	margin-left: 5px;
	width: 700px;
	text-align: center;
	font-size: 18px;
	line-height: 1.4em;
}

.home-about-text {
	display: block;
	width: 842px;
	margin: 0px auto;
	font-size: 20px;
	margin-top: 50px;
}

.manifesto-text {
	margin-top: 30px;
	line-height: 1.4em;
	font-size: 18px;
}

.manifesto-title {
	background: -webkit-linear-gradient(left, #6723d5 1%,#6723d5 1%,#244fb7 18%,#e40021 46%,#f67700 66%,#f67700 76%,#58C13A 94%);
	font-weight: 600;
	font-size: 24px;
	font-family: sf;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.about-number {
	background-color: #f3f3f3;
	color: #333333;
	font-weight: 700;
	margin-right: 8px;
	padding: 6px 11px 6px 11px;
	border-radius: 20px;
}

.tutorial-container {
	width: 80%;
	display: flex;
	margin: 0px auto;
	justify-content: center;
  align-items: center;
	flex-direction: column;
	margin-top: 120px;
}

.tutorial-header {
	font-size: 24px;
	font-weight: 600;
	background-color: #f3f3f3;
	padding: 8px 14px 8px 14px;
	border-radius: 12px;
	margin: 0px auto;
	margin-bottom: 20px;
	text-align: center;
}

.tutorial-text {
	font-weight: 500;
	line-height: 1.5em;
	color: #222222;
	text-align: center;
}

.bold-text {
	font-weight: 700;
}

.nav-labeled-buttons {
	display: inline-block;
	float:right;
}

.header-container-bottom {
	display: none;
}

.mobile-graphic-container {
	display: none;
}

.mobile-graphic-image {
	padding-top: 45px;
	display: block;
	margin: 0px auto;
	width: 85%;
	margin-bottom: 20px;
}

.contact-form-container {
	width: 76%;
	display: block;
	margin: 0px auto;
	margin-top: 120px;
	max-width: 450px;
}

.contact-web-container {
	background-color: #f3f3f3;
}

.contact-web-form-container {
	margin-top: 0px;
	padding-bottom: 55px;
}

.contact-form-container-input {
	display: flex;
  justify-content: center;
  align-items: center;
	flex-direction: column;
}

.contact-title {
	font-family: semibold;
	font-size: 22px;
	text-align: center;
	margin-bottom: 5px;
}

.contact-section-title {
	margin-bottom: 20px;
}

.contact-input {
	width: calc(100% - 28px);
	margin-top: 17px;
	background-color: #f3f3f3;
	border: 0px;
	padding: 12px 14px 10px 12px;
	font-family: medium;
	font-size: 15px;
	border-radius: 8px;
}

.contact-input:focus {
	outline-width: 0;
}

.web-input {
	font-family: inherit;
	font-weight: 600;
	width: 500px;
	padding: 11px 14px 13px 13px;
	background-color: white;
}

.contact-form-container textarea {
	height: 90px;
}

.contact-submit {
	background-color: #55BE67;
	color: white;
	font-family: semibold;
	padding: 11px 17px 8px 17px;
	font-size: 15px;
	border-radius: 50px;
	margin-top: 24px;
	cursor: pointer;
}

.contact-submit i {
	margin-right: 9px;
	font-size: 14px;
}

.contact-web-submit {
	font-family: inherit;
	font-weight: 600;
	padding: 13px 17px 14px 17px;
	font-size: 16px;
	margin-top: 30px;
	border-radius: 8px;
	transition: ease all .1s;
	background-color: #40C043;
}

.contact-web-submit:hover {
	-webkit-transform: scale(0.98);
	        transform: scale(0.98);
}

.contact-web-submit:active {
	-webkit-transform: scale(0.95);
	        transform: scale(0.95);
}

.contact-thanks-title {
	font-size: 26px;
	text-align: center;
	font-weight: 600;
	padding-top: 100px;
}

.contact-thanks-subtitle {
	text-align: center;
	margin-top: 10px;
	color: #999999;
}

.contact-slug {
	display: inline-block;
	color: white;
	font-family: semibold;
	padding: 6px 10px 3px 11px;
	border-radius: 15px;
	/* margin-left: 10px; */
	font-size: 17px;
	cursor: pointer;
	margin-bottom: 8px;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	background-color: rgb(255, 255, 255, 0.2);
}

.contact-slug .fas {
	margin-right: 4px;
	font-size: 14px;
}

.contact-form-container .loading-container {
	margin-top: 200px;
	width: 100px;
}

.future-ticker-section-container {
	margin-top: -80px;
	padding-bottom: 35px;
	background-color: #f3f3f3;
}

.reviews-section-title {
	margin-bottom: 5px;
}

.review-item {
	width: 600px;
}

.review-item-bottom {
	margin-top: 5px;
}

.review-text {
	background-color: white;
	border-radius: 8px;
	line-height: 1.3em;
	padding: 11px 14px 11px 14px;
	margin-top: 40px;
}

.review-stars {
	color: #EBBD2E;
	margin-top: 8px;
	margin-left: 6px;
	float: left;
}

.review-stars .fas {
	margin-right: 1px;
}

.review-username {
	margin-top: 8px;
	font-weight: 600;
	color: #999999;
	margin-left: 9px;
	display: inline-block;
}

.app-of-the-day {
	color: white;
	font-size: 15px;
	background-color: #F3C23E;
	font-weight: 600;
	padding: 6px 11px 8px 11px;
	border-radius: 20px;
	display: inline-block;
	margin-bottom: 14px;
}

.app-of-the-day .fas {
	font-size: 13px;
	margin-right: 6px;
}

.donate-container {
	width: 80%;
	display: flex;
	margin: 0px auto;
	justify-content: center;
  align-items: center;
	flex-direction: column;
	margin-top: 27px;
	padding-bottom: 60px;
	max-width: 420px;
}

.contribute-main-button {
	background-color: #27ae60;
	color: white;
	padding: 11px 20px 11px 20px;
	font-size: 18px;
	border-radius: 50px;
	font-weight: 700;
}

.contribute-crypto-button {
	margin-top: 10px;
	color: #555555;
	font-weight: 600;
	border-bottom: 1px solid #777777;
}

.contribute-text {
	margin-top: 27px;
}

.contribute-paragraph {
	margin-top: -4px;
	align-self: flex-start;
}

.contribute-ps {
	align-self: flex-start;
}

.crypto-label {
	font-size: 24px;
	font-weight: 700;
}

.crypto-address {
	margin-top: 15px;
	font-family: courier;
	background-color: #DDDDDD;
	padding: 10px;
	font-size: 12px;
	border-radius: 8px;
	border: 0px;
	color: #333333;
	font-weight: bold;
	width: 100%;
}

.crypto-qr {
	width: 150px;
	margin-top: 15px;
	margin-bottom: 40px;
}

.copy-label {
	color: #999999;
	font-weight: 700;
	font-size: 15px;
	padding-top: 5px;
}

.copy-label i {
	font-size: 13px;
	padding-right: 5px;
}

.donate-back-button {
	margin-left: 35px;
	margin-top: 35px;
	font-weight: 700;
	font-size: 20px;
}

.donate-back-button i {
	padding-right: 5px;
	font-size: 18px;
}

.donate-logo {
	width: 120px;
	align-self: flex-start;
	margin-bottom: 16px;
	margin-top: -3px;
}

.donate-container p a {
	text-decoration: underline;
}

.darken-section {
	position:absolute;
	z-index:9999999;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	background-color: rgb(0,0,0,0.75);
}

.construction-container {
	position: fixed;
	margin: 0px auto;
	left: 37%;
	right: 37%;
	margin-top: 350px;
	background-color: white;
	padding: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 12px;
}

.construction-container .logo-container {
	margin-left: -10px;
	-webkit-transform: scale(0.9);
	        transform: scale(0.9);
	margin-bottom: 5px;
	margin-top: -8px;
}

.construction-title {
	font-family: bold;
	font-size: 28px;
	text-align: center;
}

.construction-subtitle {
	font-family: medium;
	font-size: 20px;
	color: #444444;
	margin-top: 10px;
	text-align: center;
}

.construction-contact {
	background-color: #E3E3E3;
	padding:8px 12px 8px 12px;
	font-family: bold;
	font-size: 16px;
	border-radius: 10px;
	color: #444444;
	margin-top: 17px;
	text-align: center;
}

.farewell-container {
	width: 80%;
	display: flex;
	margin: 0px auto;
	justify-content: center;
  align-items: center;
	flex-direction: column;
  font-family: farewell;
	margin-top: 27px;
	padding-bottom: 60px;
	max-width: 490px;
  line-height: 1.27em;
}

.farewell-logo {
  width: 130px;
  margin-bottom: 25px;
  align-self: flex-start;
  margin-left: -5px;
}

.farewell-paragraph {
	margin-top: 0px;
	align-self: flex-start;
}

.farewell-container a {
  color: #0984e3;
  text-decoration: underline;
}

.farewell-name {
  font-family: farewell-bold;
  font-size: 17px;
}
@media only screen and (max-width: 1500px) {
	.top-container {
		width: 75%;
	}
}

@media only screen and (max-width: 1230px) {
	.top-container {
		width: 85%;
	}
}

@media only screen and (min-width: 600px) {
	.donate-container {
		margin-top: 45px;
	}
}

@media only screen and (max-width: 970px) {
	.top-container {
		width: 95%;
	}
}

@media only screen and (max-width: 1450px) {
	.graphic-image {
		width: 80%;
	}
}

@media only screen and (max-width: 1120px) {
	.graphic-image {
		width: 90%;
	}
}

@media only screen and (max-width: 1160px) {
	.contribute-subtitle {
		width: 54%;
	}
}

@media only screen and (max-width: 1260px) {
	.screenshot-tile {
		width: 220px;
	}
}

@media only screen and (max-width: 940px) {
	.screenshot-tiles-container {
		padding-left: 60px;
		padding-right: 60px;
	}
	.screenshot-tile {
		width: 240px;
	}
}

@media only screen and (max-width: 667px) {
	.screenshot-tiles-container {
		padding-left: 0px;
		padding-right: 0px;
	}
	.screenshot-tile {
		width: 82%;
	}
	.screenshot-tile .screenshot-tile-image {
		width: 200px;
	}
	.screenshot-tile .tile-subtitle {
		width: 100% !important;
	}
}

@media only screen and (max-width: 740px) {
	.graphic-container {
		display: none;
	}
	.mobile-graphic-container {
		display:block;
	}
	.graphic-section {
		padding-bottom: 10px;
	}
}

@media only screen and (max-width: 770px) {
	.contribute-subtitle {
		width: 70%;
		font-size: 16px;
	}
}

@media only screen and (max-width: 500px) {
	.contribute-subtitle {
		width: 80%;
	}
}

@media only screen and (max-width: 1080px) {
	.info-tile {
		width: 200px;
	}
}

@media only screen and (max-width: 1130px) {
	.info-tiles-container {
		padding-left: 180px;
		padding-right: 180px;
	}
	.info-tile {
		width: 240px;
		margin-bottom: 40px;
	}
	.values-container {
		padding-bottom: 20px !important;
	}
}

@media only screen and (max-width: 908px) {
	.info-tiles-container {
		padding-left: 120px;
		padding-right: 120px;
	}
	.construction-container {
		margin-top: 200px;
		left: 30%;
		right: 30%;
	}
}

@media only screen and (max-width: 790px) {
	.info-tiles-container {
		padding-left: 70px;
		padding-right: 70px;
	}
}

@media only screen and (max-width: 690px) {
	.info-tiles-container {
		padding-left: 90px;
		padding-right: 90px;
	}
	.info-tile {
		width: 100%;
		padding-top: 25px;
		padding-bottom: 25px;
	}
}

@media only screen and (max-width: 450px) {
	.info-tiles-container {
		padding-left: 25px;
		padding-right: 25px;
	}
}

@media only screen and (max-width: 1070px) {
	.manifesto-container {
		width: 75%;
	}
}

@media only screen and (max-width: 610px) {
	.manifesto-container {
		width: 65%;
	}
	.manifesto-title {
		display: block;
		text-align: center;
	}
}

@media only screen and (max-width: 850px) {
	.email-button {
		display: inline-block;
	}
	.nav-contact-button {
		display: none;
	}
}

@media only screen and (max-width: 750px) {
	.nav-button {
		margin-left: 15px;
	}
}

@media only screen and (max-width: 720px) {
	.review-item {
		width: 85%;
	}
}

@media only screen and (max-width: 700px) {
	.nav-labeled-buttons {
		display: none;
	}
	.email-button {
		margin-right: 30px;
	}
	.top-container {
		padding-top: 15px;
	}
	.nav-button:hover {
		background-color: white;
	}
	.nav-button:active {
		-webkit-transform: none;
		        transform: none;
	}
}

@media only screen and (max-width: 550px) {
	.mobile-header-image {
		width: 85%;
	}
	.construction-container {
		left: 20%;
		right: 20%;
	}
	.construction-title {
		font-size: 24px;
	}
	.construction-subtitle {
		font-size: 18px;
	}
}

@media only screen and (max-width: 500px) {
	.mobile-header-image {
		width: 85%;
	}
}

@media only screen and (max-width: 860px) {
	.header-container-right {
		display: none;
	}
	.ticker-section-container {
		display: none;
	}
	.header-container-bottom {
		display: block;
		margin: 0px auto;
	}
	.header-container-left-text {
		width: 100%;
		padding-left: 0px;
		padding-top: 70px;
	}
	.header-container {
		height: 670px;
	}
	.header-title {
		text-align: center;
		font-size: 32px;
	}
	.header-subtitle {
		text-align: center;
		font-size: 16px;
		margin-top: 17px;
	}
	.header-buttons-container {
		display: block;
		margin: 0px auto;
		margin-bottom: 60px;
	}
	.app-store-button {
		display: block;
		margin: 0px auto;
		margin-top: 25px;
	}
	.section-title {
		font-size: 27px;
		padding-top: 45px;
	}
	.section-subtitle {
		font-size: 16px;
	}
	.screenshot-tiles-container {
		margin-top: 30px;
	}
	.process-container, .values-container {
		padding-bottom: 30px;
	}
	.info-tiles-container {
		margin-top: 45px;
	}
	.logo-image {
		width: 30px;
		height: 30px;
		margin-right: 2px;
	}
	.logo-text {
		font-size: 28px;
	}
	.beta-text {
		font-size: 14px;
	}
	.header-buttons-container .header-button {
		font-size: 16px;
		padding: 11px 16px 13px 18px;
		font-weight: 600;
	}
	.header-button:hover, .app-store-button:hover {
		-webkit-transform: none;
		        transform: none;
	}
	.header-button:active, .app-store-button:active {
		-webkit-transform: none;
		        transform: none;
	}
	.manifesto-container .header-button {
		margin-top: 35px;
	}
	.app-store-button {
		width: 145px;
	}
	.manifesto-container {
		background-color: white;
		width: 80%;
		padding-top: 0px;
		padding-bottom: 0px;
	}
	.manifesto-section {
		padding-bottom: 55px;
	}
	.manifesto-title {
		text-align: left;
		font-size: 22px;
	}
	.manifesto-text {
		font-size: 16px;
	}
	.email-button {
		margin-right: 10px;
	}
	.tile-icon-container {
		display: none;
	}
	.mobile-tile-icon-container {
		display: inline;
		font-size: 26px;
		margin-right: 10px;
	}
	.tile-subtitle {
		margin-top: 12px;
	}
	.info-tile {
		padding-top: 10px;
	}
	.footer-text {
		display: none;
	}
	.mobile-footer-text {
		display: block;
	}
	.ticker-label {
		width: 100%;
		text-align: center;
	}
	.ticker-container {
		-webkit-animation: marquee 200s linear infinite;
		        animation: marquee 200s linear infinite;
	}
	.contact-tooltip {
		margin-left: -94px !important;
	}
	.tooltip-email-message {
		font-size: 16px !important;
	}
	.tooltip-email-button {
		font-size: 17px !important;
	}
	.review-hide-mobile {
		display: none;
	}
	.reviews-container {
		padding-top: 15px;
		padding-bottom: 115px;
	}
	.app-of-the-day-container {
		display: flex;
  	justify-content: center;
  	align-items: center;
	}
}

@media only screen and (max-width: 768px) {
   .fb-customerchat {
      display: none !important;
   }
}

.legal-container {
	width: 65%;
	display: block;
	margin: 0px auto;
	margin-top: 100px;
	padding: 0px;
	padding-bottom: 120px;
	line-height: 1.35em;
}

.legal-page-container {
	width: 85%;
	display: block;
	margin: 0px auto;
}

.legal-page-container .legal-container {
	margin-top: 30px;
}

.legal-page-container img {
	margin-top: 45px;
}

.legal-container .legal-logo {
	width: 140px;
	margin-bottom: 15px;
}

.legal-container h3 {
	margin-top: 40px;
}

.legal-container a {
	text-decoration: underline;
}

.legal-container ul {
	margin-top: 27px;
}

.legal-container li {
	margin-top: -7px;
}

.legal-subtitle {
	margin-top: 40px;
}

.bold-legal-text {
	font-weight: 600;
}

.legal-underline-text {
	text-decoration: underline;
}

@media only screen and (max-width: 970px) {
	.legal-container {
		width: 85%;
		margin-top: 50px;
	}
	.legal-page-container .legal-container {
		width: 95%;
	}
}

.legal-doc-container {
	padding-top: 40px;
}

.legal-button {
	width: 30%;
	background-color: #f3f3f3;
	margin-top: 10px;
	border-radius: 12px;
	padding-top: 4px;
	padding-bottom: 10px;
	cursor: pointer;
}

.legal-buttons-container {
	display: flex;
	justify-content: space-between;
	margin-top: 30px;
}

.selected-legal-button {
	background-color: #888888;
}

.selected-legal-button .legal-button-text, .selected-legal-button i {
	color: white !important;
}

.legal-button:hover {
	opacity: .8;
}

.legal-button-text i {
	text-align: center;
	font-size: 16px;
	margin-right: 8px;
	color: #666666;
}

.legal-button-text {
	text-align: center;
	color: #666666;
	font-family: semibold;
	font-size: 17px;
	margin-top: 10px;
}

