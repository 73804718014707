.legal-container {
	width: 65%;
	display: block;
	margin: 0px auto;
	margin-top: 100px;
	padding: 0px;
	padding-bottom: 120px;
	line-height: 1.35em;
}

.legal-page-container {
	width: 85%;
	display: block;
	margin: 0px auto;
}

.legal-page-container .legal-container {
	margin-top: 30px;
}

.legal-page-container img {
	margin-top: 45px;
}

.legal-container .legal-logo {
	width: 140px;
	margin-bottom: 15px;
}

.legal-container h3 {
	margin-top: 40px;
}

.legal-container a {
	text-decoration: underline;
}

.legal-container ul {
	margin-top: 27px;
}

.legal-container li {
	margin-top: -7px;
}

.legal-subtitle {
	margin-top: 40px;
}

.bold-legal-text {
	font-weight: 600;
}

.legal-underline-text {
	text-decoration: underline;
}

@media only screen and (max-width: 970px) {
	.legal-container {
		width: 85%;
		margin-top: 50px;
	}
	.legal-page-container .legal-container {
		width: 95%;
	}
}

.legal-doc-container {
	padding-top: 40px;
}

.legal-button {
	width: 30%;
	background-color: #f3f3f3;
	margin-top: 10px;
	border-radius: 12px;
	padding-top: 4px;
	padding-bottom: 10px;
	cursor: pointer;
}

.legal-buttons-container {
	display: flex;
	justify-content: space-between;
	margin-top: 30px;
}

.selected-legal-button {
	background-color: #888888;
}

.selected-legal-button .legal-button-text, .selected-legal-button i {
	color: white !important;
}

.legal-button:hover {
	opacity: .8;
}

.legal-button-text i {
	text-align: center;
	font-size: 16px;
	margin-right: 8px;
	color: #666666;
}

.legal-button-text {
	text-align: center;
	color: #666666;
	font-family: semibold;
	font-size: 17px;
	margin-top: 10px;
}
