@media only screen and (max-width: 1500px) {
	.top-container {
		width: 75%;
	}
}

@media only screen and (max-width: 1230px) {
	.top-container {
		width: 85%;
	}
}

@media only screen and (min-width: 600px) {
	.donate-container {
		margin-top: 45px;
	}
}

@media only screen and (max-width: 970px) {
	.top-container {
		width: 95%;
	}
}

@media only screen and (max-width: 1450px) {
	.graphic-image {
		width: 80%;
	}
}

@media only screen and (max-width: 1120px) {
	.graphic-image {
		width: 90%;
	}
}

@media only screen and (max-width: 1160px) {
	.contribute-subtitle {
		width: 54%;
	}
}

@media only screen and (max-width: 1260px) {
	.screenshot-tile {
		width: 220px;
	}
}

@media only screen and (max-width: 940px) {
	.screenshot-tiles-container {
		padding-left: 60px;
		padding-right: 60px;
	}
	.screenshot-tile {
		width: 240px;
	}
}

@media only screen and (max-width: 667px) {
	.screenshot-tiles-container {
		padding-left: 0px;
		padding-right: 0px;
	}
	.screenshot-tile {
		width: 82%;
	}
	.screenshot-tile .screenshot-tile-image {
		width: 200px;
	}
	.screenshot-tile .tile-subtitle {
		width: 100% !important;
	}
}

@media only screen and (max-width: 740px) {
	.graphic-container {
		display: none;
	}
	.mobile-graphic-container {
		display:block;
	}
	.graphic-section {
		padding-bottom: 10px;
	}
}

@media only screen and (max-width: 770px) {
	.contribute-subtitle {
		width: 70%;
		font-size: 16px;
	}
}

@media only screen and (max-width: 500px) {
	.contribute-subtitle {
		width: 80%;
	}
}

@media only screen and (max-width: 1080px) {
	.info-tile {
		width: 200px;
	}
}

@media only screen and (max-width: 1130px) {
	.info-tiles-container {
		padding-left: 180px;
		padding-right: 180px;
	}
	.info-tile {
		width: 240px;
		margin-bottom: 40px;
	}
	.values-container {
		padding-bottom: 20px !important;
	}
}

@media only screen and (max-width: 908px) {
	.info-tiles-container {
		padding-left: 120px;
		padding-right: 120px;
	}
	.construction-container {
		margin-top: 200px;
		left: 30%;
		right: 30%;
	}
}

@media only screen and (max-width: 790px) {
	.info-tiles-container {
		padding-left: 70px;
		padding-right: 70px;
	}
}

@media only screen and (max-width: 690px) {
	.info-tiles-container {
		padding-left: 90px;
		padding-right: 90px;
	}
	.info-tile {
		width: 100%;
		padding-top: 25px;
		padding-bottom: 25px;
	}
}

@media only screen and (max-width: 450px) {
	.info-tiles-container {
		padding-left: 25px;
		padding-right: 25px;
	}
}

@media only screen and (max-width: 1070px) {
	.manifesto-container {
		width: 75%;
	}
}

@media only screen and (max-width: 610px) {
	.manifesto-container {
		width: 65%;
	}
	.manifesto-title {
		display: block;
		text-align: center;
	}
}

@media only screen and (max-width: 850px) {
	.email-button {
		display: inline-block;
	}
	.nav-contact-button {
		display: none;
	}
}

@media only screen and (max-width: 750px) {
	.nav-button {
		margin-left: 15px;
	}
}

@media only screen and (max-width: 720px) {
	.review-item {
		width: 85%;
	}
}

@media only screen and (max-width: 700px) {
	.nav-labeled-buttons {
		display: none;
	}
	.email-button {
		margin-right: 30px;
	}
	.top-container {
		padding-top: 15px;
	}
	.nav-button:hover {
		background-color: white;
	}
	.nav-button:active {
		transform: none;
	}
}

@media only screen and (max-width: 550px) {
	.mobile-header-image {
		width: 85%;
	}
	.construction-container {
		left: 20%;
		right: 20%;
	}
	.construction-title {
		font-size: 24px;
	}
	.construction-subtitle {
		font-size: 18px;
	}
}

@media only screen and (max-width: 500px) {
	.mobile-header-image {
		width: 85%;
	}
}

@media only screen and (max-width: 860px) {
	.header-container-right {
		display: none;
	}
	.ticker-section-container {
		display: none;
	}
	.header-container-bottom {
		display: block;
		margin: 0px auto;
	}
	.header-container-left-text {
		width: 100%;
		padding-left: 0px;
		padding-top: 70px;
	}
	.header-container {
		height: 670px;
	}
	.header-title {
		text-align: center;
		font-size: 32px;
	}
	.header-subtitle {
		text-align: center;
		font-size: 16px;
		margin-top: 17px;
	}
	.header-buttons-container {
		display: block;
		margin: 0px auto;
		margin-bottom: 60px;
	}
	.app-store-button {
		display: block;
		margin: 0px auto;
		margin-top: 25px;
	}
	.section-title {
		font-size: 27px;
		padding-top: 45px;
	}
	.section-subtitle {
		font-size: 16px;
	}
	.screenshot-tiles-container {
		margin-top: 30px;
	}
	.process-container, .values-container {
		padding-bottom: 30px;
	}
	.info-tiles-container {
		margin-top: 45px;
	}
	.logo-image {
		width: 30px;
		height: 30px;
		margin-right: 2px;
	}
	.logo-text {
		font-size: 28px;
	}
	.beta-text {
		font-size: 14px;
	}
	.header-buttons-container .header-button {
		font-size: 16px;
		padding: 11px 16px 13px 18px;
		font-weight: 600;
	}
	.header-button:hover, .app-store-button:hover {
		transform: none;
	}
	.header-button:active, .app-store-button:active {
		transform: none;
	}
	.manifesto-container .header-button {
		margin-top: 35px;
	}
	.app-store-button {
		width: 145px;
	}
	.manifesto-container {
		background-color: white;
		width: 80%;
		padding-top: 0px;
		padding-bottom: 0px;
	}
	.manifesto-section {
		padding-bottom: 55px;
	}
	.manifesto-title {
		text-align: left;
		font-size: 22px;
	}
	.manifesto-text {
		font-size: 16px;
	}
	.email-button {
		margin-right: 10px;
	}
	.tile-icon-container {
		display: none;
	}
	.mobile-tile-icon-container {
		display: inline;
		font-size: 26px;
		margin-right: 10px;
	}
	.tile-subtitle {
		margin-top: 12px;
	}
	.info-tile {
		padding-top: 10px;
	}
	.footer-text {
		display: none;
	}
	.mobile-footer-text {
		display: block;
	}
	.ticker-label {
		width: 100%;
		text-align: center;
	}
	.ticker-container {
		animation: marquee 200s linear infinite;
	}
	.contact-tooltip {
		margin-left: -94px !important;
	}
	.tooltip-email-message {
		font-size: 16px !important;
	}
	.tooltip-email-button {
		font-size: 17px !important;
	}
	.review-hide-mobile {
		display: none;
	}
	.reviews-container {
		padding-top: 15px;
		padding-bottom: 115px;
	}
	.app-of-the-day-container {
		display: flex;
  	justify-content: center;
  	align-items: center;
	}
}

@media only screen and (max-width: 768px) {
   .fb-customerchat {
      display: none !important;
   }
}
